<template>
  <div>
    <div class="title flexB" style="display: block;">
      <h1>{{ $t("top-select-user-manager") }}</h1>
<!--      <div>
        &lt;!&ndash;        <span
        >{{ moment().format("YYYY.MM.DD(dd) HH:mm") }} {{ $t("data-today") }}</span
        >&ndash;&gt;
        <button class="medium" @click="handleRefresh">{{ $t("btn-refresh") }}</button>
      </div>-->
    </div>
    <div class="contents userMgt">
      <div class="box one filter" style="display: flex;flex-direction: row;flex-wrap: wrap;">
        <div class="flex">
          <p class="bold">{{ $t("search-keyword-title") }}</p>
          <select v-model="keywordType">
            <option value="all">{{ $t("search-keyword-all") }}</option>
            <option value="name">{{ $t("user-data-name") }}</option>
            <option value="userId">{{ $t("user-data-num") }}</option>
            <option value="loginId">{{ $t("user-data-email-id") }}</option>
            <option value="department">{{ $t("user-data-dept-name") }}</option>
            <option value="phone">{{ $t("user-data-phone-num") }}</option>
          </select>
          <input type="text" v-model="keyword" @keyup.enter="search" />

        </div>
        <div class="flex">
          <p class="bold" style="margin-left: 10px;">{{ $t("search-regdate-title") }}</p>
          <Datepicker
              v-model="startYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
          <span>~</span>
          <Datepicker
              v-model="endYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
        </div>
        <div class="flex">
          <p class="bold" style="margin-left: 10px;">{{ $t("user-data-authority") }}</p>
          <label>
            <input
                type="radio"
                v-model="userTypes"
                value=""
                name="permission"
                checked
            />{{ $t("search-keyword-all") }}
          </label>
          <label v-if="isAdmin">
            <input
                type="radio"
                v-model="userTypes"
                value="Admin"
                name="permission"
            />{{ $t("user-data-type-admin") }}
          </label>
          <label>
            <input
                type="radio"
                v-model="userTypes"
                value="User"
                name="permission"
            />{{ $t("user-data-type-user") }}
          </label>
        </div>
        <div class="flexC" style="margin-top: 10px;">
          <p class="bold" style="margin-left: 0px;">{{ $t("user-data-status") }}</p>
          <label>
            <input
                type="radio"
                name="status"
                value=""
                v-model="statuses"
                checked
            />{{ $t("search-keyword-all") }}
          </label>
          <label>
            <input
                type="radio"
                name="status"
                value="Waiting"
                v-model="statuses"
            />{{ $t("user-data-status-waiting") }}
          </label>
          <label>
            <input
                type="radio"
                name="status"
                value="Used"
                v-model="statuses"
            />{{ $t("user-data-status-used") }}
          </label>
          <label v-if="isAdmin">
            <input
                type="radio"
                name="status"
                value="NotUsed"
                v-model="statuses"
            />{{ $t("user-data-status-not-used") }}
          </label>
          <label v-if="isAdmin">
            <input
                type="radio"
                name="status"
                value="Inactive"
                v-model="statuses"
            />{{ $t("user-data-status-inactivate") }}
          </label>
          <label v-if="isAdmin">
            <input
                type="radio"
                name="status"
                value="Unsubscribe"
                v-model="statuses"
            />{{ $t("user-data-status-withdrawal") }}
          </label>
        </div>
        <div class="buttonWrap" style="width:100%; display: flex; margin-top: 15px;">
          <button class="point medium" @click="search" style="margin-left: 0px; width: 120px;">{{ $t("btn-searching") }}</button>
          <button class="medium margin6" @click="reset" style="width: 120px;">{{ $t("btn-reset") }}</button>
        </div>
      </div>
      <div class="box one">
        <h2 class="bold">
          {{ $t("data-total") }}
            <span class="blue">{{ total }}</span>
          {{ $t("data-case-ea") }}
          <router-link to="userRegister">
            <button class="small right">{{ $t("btn-register") }}</button>
          </router-link>
        </h2>
        <!-- <div class="admin-update-select">
          <h4>{{ $t("user-data-status-update") }} :</h4>
          <select class="small">
            <option value="Waiting">{{ $t("user-data-status-waiting") }}</option>
            <option value="Used">{{ $t("user-data-status-used") }}</option>
            <option value="NotUsed">{{ $t("user-data-status-not-used") }}</option>
            <option value="Inactive">{{ $t("user-data-status-inactivate") }}</option>
            <option value="Unsubscribe">{{ $t("user-data-status-withdrawal") }}</option>
          </select>
          <button @click="handleUpdate">{{ $t("btn-update") }}</button>
        </div> -->
        <table>
          <tr>
            <!-- <th><input type="checkbox" v-model="selectAll" @change="toggleSelectAll" style="border: 1px solid #c9c9c9;"></th> -->
            <th @click="sortBy('userId')">
              {{ $t("user-data-num") }}
              <button>{{ getSortIcon('userId') }}</button>
            </th>
            <th @click="sortBy('name')">
              {{ $t("user-data-name") }}
              <button>{{ getSortIcon('name') }}</button>
            </th>
            <th @click="sortBy('loginId')">
              {{ $t("user-data-email-id") }}
              <button>{{ getSortIcon('loginId') }}</button>
            </th>
            <th @click="sortBy('departmentInfo.name')">
              {{ $t("user-data-dept-name") }}
              <button>{{ getSortIcon('departmentInfo.name') }}</button>
            </th>
            <th @click="sortBy('phone')">
              {{ $t("user-data-phone-num") }}
              <button>{{ getSortIcon('phone') }}</button>
            </th>
            <th @click="sortBy('userType')">
              {{ $t("user-data-authority") }}
              <button>{{ getSortIcon('userType') }}</button>
            </th>
            <th>{{ $t("user-data-app-available") }}</th>
            <th @click="sortBy('status')">
              {{ $t("user-data-status") }}
              <button>{{ getSortIcon('status') }}</button>
            </th>
            <th @click="sortBy('registerDate')">
              {{ $t("user-data-regdate") }}
              <button>{{ getSortIcon('registerDate') }}</button>
            </th>
            <th @click="sortBy('loginDate')">
              {{ $t("user-data-last-login-date") }}
              <button>{{ getSortIcon('loginDate') }}</button>
            </th>
          </tr>
          <colgroup>
            <!-- <col style="width: 4%;"/> -->
            <col style="width: 10%;"/>
            <col style="width: 10%;"/>
            <col style="width: 15%;"/>
            <col style="width: 10%;"/>
            <col style="width: 10%;"/>
            <col style="width: 7%;"/>
            <col style="width: 10%;"/>
            <col style="width: 9%;"/>
            <col style="width: 10%;"/>
            <col style="width: 10%;"/>
          </colgroup>
          <tr v-if="total == 0">
            <td colspan="9" style="text-align: center;">
              {{ $t("search-not-found") }}
            </td>
          </tr>
          <tr v-for="(data, i) in sortedUserList" :key="i">
            <!-- <td><input type="checkbox" v-model="selectedCheckboxes" :value="data.userId" ></td> -->
            <td>{{ data.userId }}</td>
            <td>
              <a @click="handleDetail(data.userId)" class="link">{{
                  data.name
                }}</a>
            </td>
            <td>{{ data.loginId }}</td>
            <td>
              {{ data.departmentInfo.name }}
            </td>
            <td>{{ getMask(data.phone) }}</td>
            <td>
              {{
                data.userType == "User"
                    ? $t("user-data-type-user")
                    : data.userType == "Admin"
                    ? $t("user-data-type-admin")
                        : "-"
              }}
            </td>
             <td>
              {{
                data.isViewerAppAuth && data.isControlAppAuth && data.isStreamerAppAuth
                  ? $t("user-data-app-controller") + ", " + $t("user-data-app-viewer") + ", " + $t("user-data-app-streamer")
                  : data.isViewerAppAuth && data.isControlAppAuth && !data.isStreamerAppAuth
                  ? $t("user-data-app-controller") + ", " + $t("user-data-app-viewer")
                  : data.isViewerAppAuth && !data.isControlAppAuth && data.isStreamerAppAuth
                  ? $t("user-data-app-viewer") + ", " + $t("user-data-app-streamer")
                  : !data.isViewerAppAuth && data.isControlAppAuth && data.isStreamerAppAuth
                  ? $t("user-data-app-controller") + ", " + $t("user-data-app-streamer")
                  : data.isViewerAppAuth && !data.isControlAppAuth && !data.isStreamerAppAuth
                  ? $t("user-data-app-viewer")
                  : !data.isViewerAppAuth && data.isControlAppAuth && !data.isStreamerAppAuth
                  ? $t("user-data-app-controller")
                  : !data.isViewerAppAuth && !data.isControlAppAuth && data.isStreamerAppAuth
                  ? $t("user-data-app-streamer")
                  : "-"
              }}

            </td>

            <td>
              {{
                data.status == "Used"
                    ? $t("user-data-status-used")
                    : data.status == "Waiting"
                    ? $t("user-data-status-waiting")
                    : data.status == "NotUsed"
                        ? $t("user-data-status-not-used")
                        : data.status == "Inactive"
                            ? $t("user-data-status-inactivate")
                            : data.status == "Unsubscribe"
                                ? $t("user-data-status-withdrawal")
                                : ""
              }}
            </td>
            <td>
              {{ moment(data.registerDate).format("YYYY.MM.DD HH:mm:ss") }}
            </td>
            <td>
              {{
                data.loginDate == null
                    ? "--"
                    : moment(data.loginDate).format("YYYY.MM.DD HH:mm:ss")
              }}
            </td>
          </tr>
        </table>
        <div class="pagination">
          <el-pagination
              small
              layout="prev, pager, next"
              :total="total"
              :page-size="10"
              :currentPage = "currentPage + 1"
              @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.title{margin-top: 0;}
</style>
<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { fetchUserList } from "@/api/user";
import { format } from "@/mixins/format";
import {mapState} from "vuex";
export default {
  components: { Datepicker },
  mixins: [format],
  name: "UserList",
  data() {
    return {
      ko: ko,
      moment: moment,
      startYmd: null,
      endYmd: null,
      userList: [],
      total: 0,
      keyword: null,
      keywordType: "all",
      statuses: "",
      userTypes: "",
      currentPage: 0,
      selectAll: false,
      selectedCheckboxes: [],
      sortKey: '',
      sortOrder: 'asc',
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 6, sub: 0 });
    this.preset();


  },
  mounted() {
    this.callFunctionsWhenMounted();
  },
  computed: {
    ...mapState({
      isAdmin: state => state.store.isAdmin,
    }),
    sortedUserList() {
      // 정렬된 사용자 리스트 반환
      if (!this.sortKey) return this.userList;
      const sorted = [...this.userList].sort((a, b) => {
        const aValue = this.resolvePath(a, this.sortKey);
        const bValue = this.resolvePath(b, this.sortKey);

        if (aValue < bValue) return this.sortOrder === 'asc' ? -1 : 1;
        if (aValue > bValue) return this.sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
      return sorted;
    },
  },
  watch: {
    selectedCheckboxes(newVal) {
      this.selectAll = newVal.length === this.userList.length;
    }
  },
  methods: {
    preset(){
      var localStorageData = localStorage.getItem("user_searchData");
      if(localStorageData != undefined) {
        var searchData = JSON.parse(localStorageData);
        this.keyword = searchData.keyword;
        this.keywordType = searchData.keywordType;
        this.currentPage = searchData.pageNumber;
        this.startYmd =  searchData["dateRange.from"];
        this.endYmd =  searchData["dateRange.to"];
      }
    },
    persist(params){
      localStorage.setItem("user_searchData", JSON.stringify(params)); // 로컬 스토리지에 저장
    },
    search() {
      if (this.startYmd != null) {
        if (this.startYmd > this.endYmd || !this.endYmd || !this.startYmd) {
          return alert(this.$t("alert-message-check-regdate"));
        }
      }
      this.currentPage = 0;
      this.getUserList();
    },
    reset() {
      this.keywordType = "all";
      this.keyword = null;
      this.startYmd = null;
      this.endYmd = null;
    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    handleDetail(id) {
      this.$router.push({ name: "userRegister", query: { userId: id } });
    },
    handleCurrentChange(val) {
      this.currentPage = val  - 1;
      this.getUserList();
    },
    getUserList() {
      var sendUserType = this.userTypes;
      var sendStatuses = this.statuses;

      if(!this.isAdmin){
        if(sendUserType == null || sendUserType == ""){
          sendUserType = 'Manager,User';
        }
        if(sendStatuses == null || sendStatuses == ""){
          sendStatuses = 'Waiting,Used';
        }
      }


      let params = {
        pageNumber: this.currentPage, // pagination
        pageSize: 10, // pagination
        keywordType: this.keywordType,
        keyword: this.keyword,
        userTypes: sendUserType,
        statuses: sendStatuses,
      };
      params["dateRange.from"] = null;
      params["dateRange.to"] = null;

      if (this.startYmd != null&& this.endYmd != null) {
        let from = this.startYmd ? moment(this.startYmd).format("YYYY-MM-DD") : null;
        let to = this.endYmd ? moment(this.endYmd).format("YYYY-MM-DD") : null;
        params["dateRange.from"] = from;
        params["dateRange.to"] = to;
      }

      this.persist(params);
      fetchUserList(params).then((res) => {
        if (res.data.result == 0) {
          this.total = res.data.data.total;
          this.userList = res.data.data.content;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    handleRefresh() {
      this.reset();
      this.callFunctionsWhenMounted();
    },
    callFunctionsWhenMounted(){
      this.getUserList();
    },
    toggleSelectAll() {
      // selectAll 상태에 따라 모든 체크박스 선택/해제
      if (this.selectAll) {
        this.selectedCheckboxes = this.userList.map(user => user.userId);
      } else {
        this.selectedCheckboxes = [];
      }
    },
    sortBy(key) {
      // 정렬 키와 순서 업데이트
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortKey = key;
        this.sortOrder = 'asc';
      }
    },
    getSortIcon(key) {
      // 정렬 아이콘 표시
      if (this.sortKey !== key) return '⇵'; // 기본 아이콘
      return this.sortOrder === 'asc' ? '↑' : '↓'; // 정렬 방향
    },
    resolvePath(obj, path) {
      // 객체에서 경로를 따라 값을 반환
      return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    },
    handleUpdate() {
      // 선택된 항목이 없을 경우 경고
      if (this.selectedCheckboxes.length === 0) {
        alert(this.$t("선택된 항목이 없습니다.")); // 다국어 메시지 예시
        return;
      }

      // 수정 여부 확인
      const confirmUpdate = confirm(this.$t("선택된 항목을 수정하시겠습니까?"));
      if (!confirmUpdate) {
        return;
      }
      // 체크박스 초기화
      this.selectedCheckboxes = [];
      this.selectAll = false;

      // 성공 메시지
      alert(this.$t("상태가 수정되었습니다."));
    },
  },
};
</script>
